import type {
    ArchizeenTileProps,
    ObjectShorthandValue,
} from '@archipro-design/aria';
import { ArchizeenTile } from '@archipro-design/aria';
import { useRef } from 'react';
import type { LogImpressionProps } from '@archipro-website/tracker';
import { useLogImpression } from '@archipro-website/tracker';

export type TileProps = ObjectShorthandValue<ArchizeenTileProps> & {
    impression?: LogImpressionProps;
};

const ArchizeenImpressionTile = (props: TileProps) => {
    const { impression, professionalID: _, ...rest } = props;
    const ref = useRef<HTMLAnchorElement | null>(null);

    useLogImpression({ data: impression, ref });

    return <ArchizeenTile ref={ref} {...rest} />;
};

export default ArchizeenImpressionTile;
